import React from 'react'
import { Icon, Tag, Select, Input } from 'antd'
import { findWhere } from '../../../utils/db_functions';

let typologies = [];

export default {
	"fields": [
		[
			{
				type: 'select',
				key: 'performance',
				title: 'Desempenho',
				options: [],
				optionConfig: { name: 'performance' },
				loaded: false,
				colSize: 12,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
			{
				type: 'select',
				key: 'typology',
				title: 'Tipologia',
				options: [],
				optionConfig: { name: 'typology', arrayWithId: true },
				loaded: false,
				"element-attr": {
					mode: 'multiple',
					required: true
				},
				colSize: 12,
				render: (options) => {
					let opt = options.reduce((acc, v) => {
						let type = v.label.split(' ')[0]
						if (!acc[type]) {
							acc[type] = {
								children: []
							}
						}
						acc[type].children.push(v)
						return acc;

					}, {});
					let types = Object.keys(opt)
					return types.map((type, index) => {
						return (
							<Select.OptGroup label={types[index]} key={`group-${index}`}>
								{opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
							</Select.OptGroup>
						)
					})
				}
			},
		], [
			{
				type: 'select',
				key: 'sheet_width',
				title: 'Largura da folha',
				options: [],
				optionConfig: { name: 'larguras_folha' },
				loaded: false,
				colSize: 6,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
			{
				type: 'select',
				key: 'sheet_height',
				title: 'Altura da folha',
				options: [],
				optionConfig: { name: 'alturas_folha' },
				loaded: false,
				colSize: 2,
				'element-attr': {
					required: true,
				}
			},
			{
				type: 'render',
				key: 'marco_height',
				title: 'Altura do marco',
				colSize: 2,
				render: (state, module, node) => {
					return (
						<Input
							onChange={event => module.updateRefValue(node, event.target.value, 'marco_height')}
							size="large"
							placeholder='Altura Marco'
							value={state.formRef['marco_height'] || 2100}
						/>
					)
				},
				beforeSave: (module, parseObject, node) => {
					parseObject.set('marco_height', module.state.formRef.marco_height)
					return true;
				}
			},
			{
				"title": "Folhas",
				"key": "sheet_qtd",
				"type": "number",
				colSize: 2,
				"element-attr": {
					"required": true,
					"min": 1,
					"step": 1
				}
			},
			{
				type: 'select',
				key: 'section',
				title: 'Largura arremate',
				options: [
					'50', '70', '100', '200', '450', '900'
				].map(el => {
					return { 'value': el, 'label': el }
				}),
				loaded: false,
				colSize: 4,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
			{
				"title": "Módulo arremate (kit)",
				"key": "width_aliz",
				"type": "number",
				colSize: 2,
				"element-attr": {
					"required": false,
					"min": 1,
					"step": 1
				}
			},
			{
				type: 'select',
				key: 'type',
				title: 'Tipo de arreamate',
				options: [
					{ 'value': 'fixed', 'label': 'AST-F - Alizar standard face fixa' },
					{ 'value': 'adjustable', 'label': 'AST-R - Alizar standard face regulável' },
					{ 'value': 'fixed_band', 'label': 'BFS-F - Bandeira falsa face fixa' },
					{ 'value': 'adjustable_band', 'label': 'BFS-F - Bandeira falsa face regulável' },
					{ 'value': 'fixed_fusion', 'label': 'PFU-F - Painel fusion face fixa' },
					{ 'value': 'fixed_fusion_frame', 'label': 'AFF-F - Alizar fusion frame face fixa' },
					{ 'value': 'adjustable_fusion_frame', 'label': 'AFF-R - Alizar fusion frame face regulável' },
					{ 'value': 'fixed_fusion_frame_band', 'label': 'BFF-F - Bandeira fusion frame face fixa' },
					{ 'value': 'adjustable_fusion_frame_band', 'label': 'BFF-R - Bandeira fusion frame face regulável' },
					{ 'value': 'fixed_fusion_frame_panel', 'label': 'PFF-F - Painel fusion frame face fixa' },
					{ 'value': 'adjustable_fusion_frame_panel', 'label': 'PFF-R - Painel fusion frame face regulável' },
				],
				loaded: false,
				colSize: 6,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
		],
		[
			{
				title: "Alizar Standard",
				key: "component_kit",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": true, "mode": 'multiple' }
			},
		],
		[
			{
				title: "Alizar Fusion Frame",
				key: "component_kit_fusion_frame",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
		[
			{
				title: "Bandeira falsa",
				key: "component_kit_with_band",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
		[
			{
				title: "Bandeira Fusion Frame",
				key: "component_kit_with_band_fusion_frame",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
		[
			{
				title: "Painel",
				key: "component_kit_with_panel",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
		[
			{
				title: "Painel Fusion Frame",
				key: "component_kit_with_panel_fusion_frame",
				type: "relation",
				colSize: 24,
				options: [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {
						group: 'Alizar',
						// height_component: el => Number(el.props.form.state.formRef.sheet_height),
						width_component: el => Number(el.props.form.state.formRef.width_aliz)
					}
				},
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
	],
	submit: {
		collection: "MatrizAliz"
	},
	className: "config-component",
	ListWillMount: async () => {
		const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
		typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
	},
	schema: [
		{
			"title": "Desempenho",
			"key": "performance",
			"type": "text",
			render: row => (row.performance || []).map((item, key) => (<Tag key={key}>{item}</Tag>))
		},
		{
			title: 'Tipologia',
			key: 'typology',
			dataIndex: 'typology',
			type: 'render',
			render: v => {
				return (v || [])
					.map((usage, i) => {
						const typology = typologies.find(el => el.value === usage)
						return (
							<Tag key={i}>
								{typology && typology.label}
							</Tag>
						)
					})
			}

		},
		{
			title: 'Larguras da folha',
			key: 'sheet_width',
			dataIndex: 'sheet_width',
			render: v => {
				return (v || [])
					.map((sheet_width, i) => <Tag key={i}>{sheet_width}</Tag>)
			}
		},
		{
			title: 'Altura da folha',
			key: 'sheet_height',
			dataIndex: 'sheet_height',
			type: 'text'
		},
		{
			title: "Número de Folhas",
			key: "sheet_qtd",
			dataIndex: "sheet_qtd",
			type: "number",
		},
		{
			title: 'Seção',
			key: 'section',
			type: 'text',
			render: row => (row.section || []).map((item, key) => (<Tag key={key}>{item}</Tag>))

		},
		{
			title: 'Largura do alizar',
			key: 'width_aliz',
			dataIndex: 'width_aliz',
			type: 'number',

		},
	],
	"title-navigation": <div><Icon type="table" /> Matriz de Arremates</div>,
	"title-module": "Matriz de Arremates",
	"module": "MatrizAliz",
	"router-base": "/panel/matriz-arremates"
}