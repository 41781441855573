import { Col, Divider, Form, Row, Select } from "antd";
import _ from "lodash";
import React, { Fragment } from "react"
import { setAlizFinishingEqualMarco } from "../../../../suport-files/proposal-functions.jsx";

const productStepAlizar = ({
    index,
    getProductValue,
    updateProductData,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    filterComponentBySheetFinishing,
    _this,
}) => {
    const getMarcoThicknessInfo = (id, attr) => {
        if (!id) return ''

        const marcoThickness = (_this.state.marcoThicknessMatriz || []).find(el => el.id === id)

        if (!marcoThickness) return ''

        return marcoThickness.get(attr)
    }

    return (
        <Fragment>
            <Row gutter={12} className="chunk">
                <h2>Cadastro de Arremates</h2>
            </Row>
            <Row gutter={24}>
                <Col span={3}>
                    <Form.Item label='Face'>
                        <div style={{ fontWeight: 'bold' }}>
                            Fixa
                        </div>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Alizar">
                        <Select
                            value={getProductValue('aliz_fixed_section_')}
                            onChange={value => {
                                const valueNumber = parseInt(value.replace('x', '').replace('-', ''))
                                updateProductData('aliz_fixed_type', index, valueNumber < 100 ? 'component_kit' : 'component_kit_fusion_frame')
                                updateProductData('aliz_fixed_section', index, value) // Atribuir o valor do alizar_ para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('aliz_fixed_section_', index, value)

                                // Resetar os valores
                                updateProductData('fixed_aliz', index, null)
                                updateProductData('finishing_aliz', index, null)
                                updateProductData('pattern_aliz', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: value.toString(), adjustable: '' },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('fixed_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('finishing_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('fixed_aliz_option_finishing', index, (ids.fixed || '__').split('__')[1])
                                            updateProductData('pattern_aliz', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        alizType: getProductValue('aliz_fixed_type')
                                    })
                                }
                            }}
                            placeholder="Alizar" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .filter(matriz => matriz.get('component_kit').length || matriz.get('component_kit_fusion_frame').length)
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .filter(el => el <= 100)
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>Alizar {el < 100 ? 'Standard' : 'Fusion frame'} {el}mm</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Bandeira">
                        <Select
                            value={getProductValue('band_fixed_section')}
                            onChange={value => {
                                const valueNumber = parseInt(value.replace('x', '').replace('-', ''))
                                updateProductData('aliz_fixed_type', index, valueNumber < 100 ? 'component_kit_with_band' : 'component_kit_with_band_fusion_frame')
                                updateProductData('aliz_fixed_section', index, value) // Atribuir o valor da bandeira para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('band_fixed_section', index, value)

                                // Resetar os valores
                                updateProductData('fixed_aliz', index, null)
                                updateProductData('finishing_aliz', index, null)
                                updateProductData('pattern_aliz', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: value.toString(), adjustable: '' },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('fixed_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('finishing_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('fixed_aliz_option_finishing', index, (ids.fixed || '__').split('__')[1])
                                            updateProductData('pattern_aliz', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        alizType: getProductValue('aliz_fixed_type')
                                    })
                                }
                            }}
                            placeholder="Bandeira" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .filter(matriz => matriz.get('component_kit_with_band').length || matriz.get('component_kit_with_band_fusion_frame').length)
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .filter(el => el <= 100)
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>Bandeira {el < 100 ? 'falsa c/ alizar Standard' : 'Fusion frame c/ alizar'} {el}mm</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Painel">
                        <Select
                            value={getProductValue('panel_fixed_section')}
                            onChange={value => {
                                updateProductData('aliz_fixed_type', index, !value.includes('_fusion-frame') ? 'component_kit_with_panel' : 'component_kit_with_panel_fusion_frame')
                                updateProductData('aliz_fixed_section', index, value) // Atribuir o valor do painel para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('panel_fixed_section', index, value)

                                // Resetar os valores
                                updateProductData('fixed_aliz', index, null)
                                updateProductData('finishing_aliz', index, null)
                                updateProductData('pattern_aliz', index, null)

                                if (value) {
                                    value = value.replace('_fusion-frame', '')
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: value.toString(), adjustable: '' },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('fixed_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('finishing_aliz', index, (ids.fixed || '__').split('__')[0])
                                            updateProductData('fixed_aliz_option_finishing', index, (ids.fixed || '__').split('__')[1])
                                            updateProductData('pattern_aliz', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        alizType: getProductValue('aliz_fixed_type')
                                    })
                                }
                            }}
                            placeholder="Painel" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {

                                (_this.state.alizMatriz || [])
                                    .filter(matriz => matriz.get('component_kit_with_panel').length || matriz.get('component_kit_with_panel_fusion_frame').length)
                                    .flat().length > 1 && (
                                    <>
                                        <Select.Option value='-15x'>Painel Fusion 1800x15mm</Select.Option>
                                        <Select.Option value='-15x_fusion-frame'>Painel Fusion frame 1800x15mm</Select.Option>
                                    </>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={3}>
                    <Form.Item label='Face'>
                        <div style={{ fontWeight: 'bold' }}>
                            Regulável
                        </div>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Alizar">
                        <Select
                            value={getProductValue('aliz_adjustable_section_')}
                            onChange={async value => {
                                const valueNumber = parseInt(value.replace('x', '').replace('-', ''))
                                updateProductData('aliz_adjustable_type', index, valueNumber < 100 ? 'component_kit' : 'component_kit_fusion_frame')
                                updateProductData('aliz_adjustable_section', index, value) // Atribuir o valor do alizar_ para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('aliz_adjustable_section_', index, value)

                                // Resetar os valores
                                updateProductData('adjustable_aliz', index, null)
                                updateProductData('finishing_aliz_adjustable', index, null)
                                updateProductData('pattern_aliz_adjustable', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: '', adjustable: value.toString() },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('adjustable_aliz', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('finishing_aliz_adjustable', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('adjustable_aliz_option_finishing', index, (ids.adjustable || '__').split('__')[1])
                                            updateProductData('pattern_aliz_adjustable', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        adjustableType: getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz'),
                                        alizType: getProductValue('aliz_adjustable_type'),
                                    })
                                }
                            }}
                            placeholder="Alizar" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .filter(matriz => matriz.get('component_kit').length || matriz.get('component_kit_fusion_frame').length)
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .filter(el => el <= 100)
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>Alizar {el < 100 ? 'Standard' : 'Fusion frame'} {el}mm</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Bandeira">
                        <Select
                            value={getProductValue('band_adjustable_section')}
                            onChange={async value => {
                                const valueNumber = parseInt(value.replace('x', '').replace('-', ''))
                                updateProductData('aliz_adjustable_type', index, valueNumber < 100 ? 'component_kit_with_band' : 'component_kit_with_band_fusion_frame')
                                updateProductData('aliz_adjustable_section', index, value) // Atribuir o valor da bandeira para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('band_adjustable_section', index, value)

                                // Resetar os valores
                                updateProductData('adjustable_aliz', index, null)
                                updateProductData('finishing_aliz_adjustable', index, null)
                                updateProductData('pattern_aliz_adjustable', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: '', adjustable: value.toString() },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('adjustable_aliz', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('finishing_aliz_adjustable', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('adjustable_aliz_option_finishing', index, (ids.adjustable || '__').split('__')[1])
                                            updateProductData('pattern_aliz_adjustable', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        adjustableType: getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz'),
                                        alizType: getProductValue('aliz_adjustable_type'),
                                    })
                                }
                            }}
                            placeholder="Bandeira" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .filter(matriz => matriz.get('component_kit_with_band').length || matriz.get('component_kit_with_band_fusion_frame').length)
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .filter(el => el <= 100)
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>Bandeira {el < 100 ? 'falsa c/ alizar Standard' : 'Fusion frame c/ alizar'} {el}mm</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Painel">
                        <Select
                            value={getProductValue('panel_adjustable_section')}
                            onChange={async value => {
                                updateProductData('aliz_adjustable_type', index, !value.includes('_fusion-frame') ? 'component_kit_with_panel' : 'component_kit_with_panel_fusion_frame')
                                updateProductData('aliz_adjustable_section', index, value) // Atribuir o valor do painel para o alizar, pois é o que será utilizado na função de setAlizFinishingEqualMarco
                                updateProductData('panel_adjustable_section', index, value)

                                // Resetar os valores
                                updateProductData('adjustable_aliz', index, null)
                                updateProductData('finishing_aliz_adjustable', index, null)
                                updateProductData('pattern_aliz_adjustable', index, null)

                                if (value) {
                                    value = value.replace('_fusion-frame', '')
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco'),
                                        finishingId: (getProductValue('finishing_marco') && getProductValue('marco_option_finishing')) ? getProductValue('marco_option_finishing') : null,
                                        sections: { fixed: '', adjustable: value.toString() },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('adjustable_aliz', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('finishing_aliz_adjustable', index, (ids.adjustable || '__').split('__')[0])
                                            updateProductData('adjustable_aliz_option_finishing', index, (ids.adjustable || '__').split('__')[1])
                                            updateProductData('pattern_aliz_adjustable', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: true,
                                        adjustableType: getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz'),
                                        alizType: getProductValue('aliz_adjustable_type'),
                                    })
                                }
                            }}
                            placeholder="Painel" size="large"
                            disabled={_this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                (_this.state.alizMatriz || [])
                                    .filter(matriz => matriz.get('component_kit_with_panel').length || matriz.get('component_kit_with_panel_fusion_frame').length)
                                    .flat().length > 1 && (
                                    <>
                                        <Select.Option value='-15x'>Painel Fusion 1800x15mm</Select.Option>
                                        <Select.Option value='-15x_fusion-frame'>Painel Fusion frame 1800x15mm</Select.Option>
                                    </>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Divider dashed={true} />
        </Fragment>
    )
}

export default productStepAlizar