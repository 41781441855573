import { Button, Col, Divider, Form, InputNumber, Row, Select } from "antd"
import { setPanelFinishingEqualSheet } from "../../../suport-files/proposal-functions"
import React, { Fragment } from "react"

const productStepPanel = ({
    blockIndex,
    index,
    v,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    _this,
    getProductValue,
}) => {
    return (
        <Fragment>
            <Row gutter={24}>
                <h2>
                    Cadastro do Painel Parede
                    <Button icon="plus" shape="circle" size="large" disabled={_this.props.disabledFields} style={{ marginLeft: 16 }} onClick={() => _this.addNewWallPanel(blockIndex, index)} />
                </h2>

                {v.wall_panel.map((wallPanel, wallPanelIndex, wallPanelList) => {

                    const getWallPanelValue = (attr, index, wallPanelIndex) => {
                        return _this.getWallPanelValue(attr, blockIndex, index, wallPanelIndex)
                    }

                    const updateWallValue = (attr, index, value) => {
                        return _this.updateWallValue(attr, blockIndex, index, wallPanelIndex, value)
                    }

                    return (
                        <Fragment>
                            <Row gutter={24} key={`panel_${wallPanelIndex}`}>
                                <Col span={10}>
                                    <Form.Item label='Tipo de Painel'>
                                        <Select
                                            value={getWallPanelValue('panel_wall_dimensions', index, wallPanelIndex)}
                                            onChange={value => {
                                                updateWallValue('panel_wall_dimensions', index, value.split('xfusionframe')[0])

                                                if (value) {
                                                    updateWallValue('panel_wall_module', index, null)
                                                    updateWallValue('panel_wall_pattern', index, null)

                                                    setPanelFinishingEqualSheet({
                                                        panelsMatriz: _this.state.panelsMatriz,
                                                        wallPanelType: 'panel_15mm',
                                                        wallPanelDimensions: value,
                                                        finishings: _this.props.finishings,
                                                        finishingId: getProductValue('finishing_sheet').includes("__") ? getProductValue('finishing_sheet').split("__")[1] : null,
                                                        patternId: getProductValue('pattern_sheet'),
                                                        sheetComponents: _this.state.kitsComponents['comp_folha'],
                                                        sheetId: getProductValue('finishing_sheet').includes("__") ? getProductValue('finishing_sheet').split("__")[0] : getProductValue('finishing_sheet'),
                                                        updateFunction: (data) => {
                                                            updateWallValue('panel_wall_module', index, data.finishing)
                                                            updateWallValue('panel_wall_pattern', index, data.pattern)
                                                        }
                                                    })
                                                }
                                            }}
                                            placeholder="Tipo de Painel" size="large"
                                            disabled={_this.props.disabledFields}
                                        >
                                            <Select.Option value=''>Selecione...</Select.Option>
                                            <Select.Option value="900x2700">Painel 900x2700x15mm</Select.Option>
                                            <Select.Option value="900x2700xfusionframe">Painel Fusion frame 900x2700x15mm</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <Form.Item label="Quantidade">
                                        <InputNumber
                                            value={getWallPanelValue('wall_quantity', index, wallPanelIndex)}
                                            min={1}
                                            onChange={value => {
                                                updateWallValue('wall_quantity', index, value)
                                                // Apagar valor do módulo caso tenha apagado valor da quantidade
                                                if (!value) {
                                                    updateWallValue('panel_wall_type', index, null)
                                                    updateWallValue('panel_wall_module', index, null)
                                                    updateWallValue('panel_wall_finishing', index, null)
                                                    updateWallValue('panel_wall_pattern', index, null)
                                                }
                                            }}
                                            placeholder="Quantidade"
                                            size="large" disabled={_this.props.disabledFields}
                                            type='number' />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label='Acabamento'>
                                        <Select
                                            value={getWallPanelValue('panel_wall_module', index, wallPanelIndex)}
                                            onChange={value => {
                                                updateWallValue('panel_wall_module', index, value)

                                                if (value) {
                                                    updateWallValue('panel_wall_pattern', index, null)
                                                }
                                            }}
                                            placeholder="Acabamento" size="large"
                                            disabled={_this.props.disabledFields}
                                        >
                                            <Select.Option value=''>Selecione...</Select.Option>
                                            {
                                                (_this.state.panelsMatriz || [])
                                                    .map(matriz => matriz.get('panel_15mm'))
                                                    .flat()
                                                    .filter(el => {
                                                        const [width_component, height_component] = (getWallPanelValue('panel_wall_dimensions', index, wallPanelIndex) || 'x').split('x')
                                                        return (
                                                            el &&
                                                            el.get('width_component') === parseInt(width_component || 0) &&
                                                            el.get('height_component') === parseInt(height_component || 0)
                                                        )
                                                    })
                                                    .map(el => {
                                                        const finishing = _this.props.finishings.find(finishing => finishing.finishingId === el.get('finishing').id) || null
                                                        return {
                                                            ...finishing,
                                                            module: el.id
                                                        }
                                                    })
                                                    .filter(el => el)
                                                    .sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
                                                    .map(el => <Select.Option value={el.module}>{el.code} - {el.description}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item label="Padrão de cor">
                                        <Select
                                            value={getWallPanelValue('panel_wall_pattern', index, wallPanelIndex)}
                                            onChange={value => updateWallValue('panel_wall_pattern', index, value)}
                                            placeholder="Padrão de cor" size="large"
                                            disabled={_this.props.disabledFields}
                                        >
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {
                                                (
                                                    getPatternOfFinishingsComponent(
                                                        getWallPanelValue('panel_wall_module', index, wallPanelIndex),
                                                        (_this.state.panelsMatriz || [])
                                                            .map(matriz => matriz.get('panel_15mm'))
                                                            .flat()
                                                            .filter(el => el),
                                                        _this.props.finishings
                                                    )
                                                )
                                                    .map(el => {
                                                        return (
                                                            <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                                        );
                                                    })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <Form.Item label="Remover">
                                        <Button icon="close" shape="circle" size="large" disabled={_this.props.disabledFields} onClick={() => _this.removeWallPanel(blockIndex, index, wallPanelIndex)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Fragment>
                    );
                })}
            </Row>
            <Divider dashed={true} />
        </Fragment>
    )
}

export default productStepPanel