import { Col, Divider, Form, Popover, Row, Select } from "antd";
import _ from "lodash";
import { setAlizFinishingEqualMarco } from "../../../suport-files/proposal-functions.jsx";
import React, { Fragment } from "react"

const productStepAlizar = ({
    index,
    getProductValue,
    updateProductData,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    filterComponentBySheetFinishing,
    _this,
}) => {
    const getAdjustableType = (description) => {
        if ((description || '').includes('AF1')) return '(AF1)'
        else if ((description || '').includes('AR1')) return '(AR1)'
        else if ((description || '').includes('AR2')) return '(AR2)'
        else if ((description || '').includes('AR3')) return '(AR3)'
        else if ((description || '').includes('AR4')) return '(AR4)'
        else if ((description || '').includes('AR5')) return '(AR5)'
        else return ''
    }

    const getMarcoThicknessInfo = (id, attr) => {
        if (!id) return ''

        const marcoThickness = (_this.state.marcoThicknessMatriz || []).find(el => el.id === id)

        if (!marcoThickness) return ''

        return marcoThickness.get(attr)
    }

    return (
        <Fragment>
            <Row gutter={12} className="chunk">
                <h2>Cadastro de Alizares</h2>
            </Row>

            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item label="Alizar Fixo">
                        <Select
                            value={
                                getProductValue('aliz_options') !== true && getProductValue('aliz_options') !== false ? null :
                                    getProductValue('aliz_options')
                            }
                            onChange={value => {
                                updateProductData('aliz_options', index, value)

                                if (!value) {
                                    updateProductData('aliz_fixed_section', index, null)
                                    updateProductData('fixed_aliz', index, null)
                                    updateProductData('pattern_aliz', index, null)
                                }
                            }}
                            placeholder="Alizar Fixo" size="large"
                            disabled={_this.props.disabledFields || !(['Madeira', 'material_4'].includes(getProductValue('marco_material')))}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            <Select.Option value={true}>Sim</Select.Option>
                            <Select.Option value={false}>Não</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Alizar Regulável">
                        <Select
                            value={
                                getProductValue('aliz_options_01') !== true && getProductValue('aliz_options_01') !== false ? null :
                                    getProductValue('aliz_options_01')
                            }
                            onChange={value => {
                                updateProductData('aliz_options_01', index, value)

                                if (!value) {
                                    updateProductData('aliz_adjustable_section', index, null)
                                    updateProductData('adjustable_aliz', index, null)
                                    updateProductData('pattern_aliz_adjustable', index, null)
                                }
                            }}
                            placeholder="Alizar Regulável" size="large"
                            disabled={_this.props.disabledFields || !(['Madeira', 'material_4'].includes(getProductValue('marco_material')))}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            <Select.Option value={true}>Sim</Select.Option>
                            <Select.Option value={false}>Não</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Bandeira Painel">
                        <Select
                            value={getProductValue('aliz_options_02')}
                            onChange={value => {
                                updateProductData('aliz_options_02', index, value)
                            }}
                            placeholder="Bandeira Painel" size="large"
                            disabled={_this.props.disabledFields || !(['Madeira', 'material_4'].includes(getProductValue('marco_material')))}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            <Select.Option value='fixed'>Face fixa</Select.Option>
                            <Select.Option value='adjustable'>Face regulável</Select.Option>
                            <Select.Option value='fixed_adjustable'>2 faces</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={5}>
                    <Form.Item label='Face'>
                        <div style={{ fontWeight: 'bold' }}>
                            Fixa
                        </div>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="Seção">
                        <Select
                            value={getProductValue('aliz_fixed_section')}
                            onChange={value => {
                                updateProductData('aliz_fixed_section', index, value)
                                updateProductData('fixed_aliz', index, null)
                                updateProductData('finishing_aliz', index, null)
                                updateProductData('pattern_aliz', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco').includes("__") ? getProductValue('finishing_marco').split("__")[0] : getProductValue('finishing_marco'),
                                        finishingId: getProductValue('finishing_marco').includes("__") ? getProductValue('finishing_marco').split("__")[1] : null,
                                        sections: { fixed: value.toString(), adjustable: '' },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            if (getProductValue('aliz_options') !== false) {
                                                updateProductData('fixed_aliz', index, ids.fixed)
                                                updateProductData('finishing_aliz', index, ids.fixed)
                                                updateProductData('pattern_aliz', index, getProductValue('pattern_marco'))
                                            }
                                        },
                                        newMethodology: false,
                                    })
                                }
                            }}
                            placeholder="Seção" size="large"
                            disabled={!getProductValue('aliz_options') || _this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>{el}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Acabamento">
                        <Select
                            value={getProductValue('fixed_aliz')}
                            onChange={async value => {
                                updateProductData('fixed_aliz', index, value)
                                updateProductData('finishing_aliz', index, value)
                                updateProductData('pattern_aliz', index, null)
                            }}
                            placeholder="Acabamento" size="large"
                            disabled={!getProductValue('aliz_options') || _this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                _.uniqBy(
                                    filterComponentBySheetFinishing(
                                        _this.state.kitsComponents['comp_folha'],
                                        getProductValue('finishing_sheet'),
                                        (_this.state.alizMatriz || [])
                                            .map(matriz => [...matriz.get('component_kit'), ...(matriz.get('component_kit_with_band') || [])])
                                            .flat()
                                            .filter(el => el)
                                        ,
                                        _this.props.finishings,
                                        _this.props.matrizFinishing,
                                        getProductValue('sheet_option_finishing'),
                                    )
                                        .filter(el => {
                                            return (
                                                el.get('finishing') &&
                                                ((getProductValue('aliz_fixed_section') || '').slice(1, -1) === (el.get('fill_componente') || '').toString()) &&
                                                el.get('description_component').toLowerCase().includes('fix') &&
                                                (
                                                    (getProductValue('aliz_options_02') || '').includes('fixed') ?
                                                        el.get('description_component').toLowerCase().includes('bandeira') : !el.get('description_component').toLowerCase().includes('bandeira')
                                                )
                                            )
                                        })
                                        .map(el => {
                                            const finishing = _this.props.finishings.find(finishing => finishing.finishingId === el.get('finishing').id) || null
                                            return {
                                                ...finishing,
                                                alizarFinishing: el.id,
                                                alizarDescription: el.get('description_component'),
                                                adjustableType: getAdjustableType(el.get('description_component'))
                                            }
                                        }).filter(el => el)
                                    , 'alizarFinishing')
                                    .sort((a, b) => (`${a['code']} ${a['adjustableType']}` > `${b['code']} ${b['adjustableType']}`) ? 1 : ((`${b['code']} ${b['adjustableType']}` > `${a['code']} ${a['adjustableType']}`) ? -1 : 0))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el['alizarFinishing']}>
                                                <Popover content={el['alizarDescription']}>
                                                    {el['code']} - {el['description']} {el['adjustableType']}
                                                </Popover>
                                            </Select.Option>
                                        )
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Padrão de cor">
                        <Select
                            value={getProductValue('pattern_aliz')}
                            onChange={value => updateProductData('pattern_aliz', index, value)} placeholder="Padrão de cor" size="large"
                            disabled={!getProductValue('aliz_options') || _this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                (getPatternOfFinishingsComponent(getProductValue('fixed_aliz'), (_this.state.alizMatriz || []).map(el => [...el.get('component_kit'), ...(el.get('component_kit_with_band') || [])]).flat(), _this.props.finishings))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={24}>
                <Col span={5}>
                    <Form.Item label='Face'>
                        <div style={{ fontWeight: 'bold' }}>
                            Regulável
                        </div>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label="Seção">
                        <Select
                            value={getProductValue('aliz_adjustable_section')}
                            onChange={async value => {
                                updateProductData('aliz_adjustable_section', index, value)
                                updateProductData('adjustable_aliz', index, null)
                                updateProductData('finishing_aliz_adjustable', index, null)
                                updateProductData('pattern_aliz_adjustable', index, null)

                                if (value) {
                                    setAlizFinishingEqualMarco({
                                        alizMatriz: _this.state.alizMatriz,
                                        marcoComponents: _this.state.kitsComponents['comp_marco'],
                                        marcoId: getProductValue('finishing_marco').includes("__") ? getProductValue('finishing_marco').split("__")[0] : getProductValue('finishing_marco'),
                                        finishingId: getProductValue('finishing_marco').includes("__") ? getProductValue('finishing_marco').split("__")[1] : null,
                                        sections: { fixed: '', adjustable: value.toString() },
                                        panelFlag: getProductValue('aliz_options_02'),
                                        updateFunction: (ids) => {
                                            updateProductData('adjustable_aliz', index, ids.adjustable)
                                            updateProductData('finishing_aliz_adjustable', index, ids.adjustable)
                                            updateProductData('pattern_aliz_adjustable', index, getProductValue('pattern_marco'))
                                        },
                                        newMethodology: false,
                                        adjustableType: getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz'),
                                    })
                                }
                            }}
                            placeholder="Seção" size="large"
                            disabled={!getProductValue('aliz_options_01') || _this.props.disabledFields}
                        >
                            <Select.Option value=''>Selecione...</Select.Option>
                            {
                                _.uniq(
                                    (_this.state.alizMatriz || [])
                                        .map(matriz => matriz.get('section'))
                                        .flat()
                                )
                                    .map(el => parseInt(el))
                                    .sort((a, b) => a - b)
                                    .map(el => {
                                        return (
                                            <Select.Option value={`-${el}x`}>{el}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Acabamento">
                        <Select
                            value={getProductValue('adjustable_aliz')}
                            onChange={value => {
                                updateProductData('adjustable_aliz', index, value)
                                updateProductData('finishing_aliz_adjustable', index, value)
                                updateProductData('pattern_aliz_adjustable', index, null)
                            }}
                            placeholder="Acabamento" size="large"
                            disabled={!getProductValue('aliz_options_01') || _this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                _.uniqBy(
                                    filterComponentBySheetFinishing(
                                        _this.state.kitsComponents['comp_folha'],
                                        getProductValue('finishing_sheet'),
                                        (_this.state.alizMatriz || [])
                                            .map(matriz => [...matriz.get('component_kit'), ...(matriz.get('component_kit_with_band') || [])])
                                            .flat()
                                            .filter(el => el)
                                        ,
                                        _this.props.finishings,
                                        _this.props.matrizFinishing,
                                        getProductValue('sheet_option_finishing'),
                                    )
                                        .filter(el => {
                                            return (
                                                el.get('finishing') &&
                                                ((getProductValue('aliz_adjustable_section') || '').slice(1, -1) === (el.get('fill_componente') || '').toString()) &&
                                                el.get('description_component').toLowerCase().includes('regul') &&
                                                (
                                                    (getProductValue('aliz_options_02') || '').includes('adjustable') ?
                                                        el.get('description_component').toLowerCase().includes('bandeira') : !el.get('description_component').toLowerCase().includes('bandeira')
                                                )
                                            )
                                        })
                                        .map(el => {
                                            const finishing = _this.props.finishings.find(finishing => finishing.finishingId === el.get('finishing').id) || null
                                            return {
                                                ...finishing,
                                                alizarFinishing: el.id,
                                                alizarDescription: el.get('description_component'),
                                                adjustableType: getAdjustableType(el.get('description_component'))
                                            }
                                        }).filter(el => el && (el.adjustableType.includes(`(${getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz')})`)))
                                    , 'alizarFinishing')
                                    .sort((a, b) => (`${a['code']} ${a['adjustableType']}` > `${b['code']} ${b['adjustableType']}`) ? 1 : ((`${b['code']} ${b['adjustableType']}` > `${a['code']} ${a['adjustableType']}`) ? -1 : 0))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el['alizarFinishing']}>
                                                <Popover content={el['alizarDescription']} style={{ zIndex: 50 }} >
                                                    {el['code']} - {el['description']} {el['adjustableType']}
                                                </Popover>
                                            </Select.Option>
                                        )
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Padrão de cor">
                        <Select
                            value={getProductValue('pattern_aliz_adjustable')}
                            onChange={value => updateProductData('pattern_aliz_adjustable', index, value)}
                            placeholder="Padrão de cor" size="large"
                            disabled={!getProductValue('aliz_options_01') || _this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                (getPatternOfFinishingsComponent(getProductValue('adjustable_aliz'), (_this.state.alizMatriz || []).map(el => [...el.get('component_kit'), ...(el.get('component_kit_with_band') || [])]).flat(), _this.props.finishings))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>

            </Row>

            <Divider dashed={true} />
        </Fragment>
    )
}

export default productStepAlizar