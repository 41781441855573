import { defaultOrderDocsTypes } from '../../utils/defaultDataSource';
import { techDocs } from '../../modules/e-learning/technical-docs';
import * as Modules from '../share/moduleComponents';

const sideMenu = [
    {
        title: 'Comercial',
        icon: 'inbox',
        key: 'proposal-configs',
        submenus: [
            {
                key: 'qualification-survey',
                icon: 'message',
                permission: 'QualificationSurveyRead',
                module: Modules.QualificationSurveyModule,
            },
            {
                key: 'soli-pro',
                icon: 'paper-clip',
                permission: 'ProposalRequestRead',
                module: Modules.ProposalRequestModule,
            },
            {
                key: 'proposal',
                icon: 'file-ppt',
                permission: 'ProposalRead',
                module: Modules.ProposalModule,
            },
            {
                key: 'gest-pro',
                icon: 'container',
                permission: 'ProposalManagementRead',
                module: Modules.ProposalManagementModule,
            },
            {
                key: 'tabela-precos',
                icon: 'table',
                permission: 'PriceListRead',
                module: Modules.PriceListModule,
            },
            {
                key: 'emitir-ped',
                icon: 'folder',
                permission: 'OrderRead',
                module: Modules.OrderModule,
            },
            {
                key: 'client',
                icon: 'idcard',
                permission: 'ClientRead',
                module: Modules.ClientsModule,
            },
            {
                key: 'rep-pro',
                icon: 'team',
                permission: 'TradeRepresentativeRead',
                module: Modules.TradeRepresentativeModule,
            },
            {
                key: 'pesquisa-atendimento',
                icon: 'message',
                permission: 'CustomerSatisfactionSurveyRead',
                module: Modules.CustomerSatisfactionSurveyModule,
            },
            {
                key: 'gest-com',
                icon: 'bar-chart',
                permission: 'ComercialManagementRead',
                module: Modules.CommercialManagementModule,
            },
            {
                key: 'commercial-quality-record',
                icon: 'audit',
                permission: 'CommercialQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'commercial-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/commercial-quality-record',
                    icon: 'audit',
                    module: 'CommercialQualityRecord',
                }),
            },
            {
                key: 'commercial-quality-procedures',
                icon: 'audit',
                permission: 'CommercialQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'commercial-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/commercial-quality-procedures',
                    icon: 'audit',
                    module: 'CommercialQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'Gestão do Pedido',
        icon: 'folder',
        key: 'order-manager',
        submenus: [
            {
                key: 'order-map',
                icon: 'table',
                permission: 'OrderMapRead',
                module: Modules.OrderMapModule,
            },
            {
                key: 'order-folder',
                icon: 'folder-open',
                permission: 'OrderFolderRead',
                module: Modules.OrderFolderModule,
            },
            {
                key: 'technical-order',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Pedido Técnico' },
            },
            {
                key: 'consolidated-order',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Pedido Consolidado' },
            },
            {
                key: 'executive-project',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Projeto Executivo' },
            },
            {
                key: 'order-status',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Status do Pedido' },
            },
        ],
    },
    {
        title: 'ADM vendas',
        icon: 'area-chart',
        key: 'adm-vendas',
        submenus: [
            {
                key: 'order-schedule',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Cronograma de Pedidos' },
            },
            {
                key: 'contract-manager',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Gestão de Contratos' },
            },
            {
                key: 'to-receive',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Contas a receber' },
            },
            {
                key: 'comissoes',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Comissões' },
            },
            {
                key: 'faturamento',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Faturamento' },
            },
            {
                key: 'adm-vendas-reports',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Relatórios - ADM Vendas' },
            },
            {
                key: 'adm-vendas-quality-record',
                icon: 'audit',
                permission: 'AdmVendasQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'adm-vendas-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/adm-vendas-quality-record',
                    icon: 'audit',
                    module: 'AdmVendasQualityRecord',
                }),
            },
            {
                key: 'adm-vendas-quality-procedures',
                icon: 'audit',
                permission: 'AdmVendasQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'adm-vendas-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/adm-vendas-quality-procedures',
                    icon: 'audit',
                    module: 'AdmVendasQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'Projetos P&D',
        icon: 'book',
        key: 'projects',
        submenus: [
            {
                key: 'projects-schedule',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Cronograma de Projetos' },
            },
            {
                key: 'feasibility-analysis',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Análise de viabilidade' },
            },
            {
                key: 'compatible-projects',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Projetos compatibilizados' },
            },
            {
                key: 'product-standardization',
                icon: 'control',
                permission: 'ProductStandardizationRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'product-standardization',
                    titleModule: 'Padronização de Produtos',
                    routerBase: '/panel/product-standardization',
                    icon: 'control',
                    module: 'ProductStandardization',
                }),
            },
            {
                key: 'bim',
                icon: 'edit',
                permission: 'BimRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'bim',
                    titleModule: 'BIM',
                    routerBase: '/panel/bim',
                    icon: 'edit',
                    module: 'Bim',
                }),
            },
            {
                key: 'factory-details',
                icon: 'project',
                permission: 'FactoryDetailsRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'factory-details',
                    titleModule: 'Detalhes de Fabricação',
                    routerBase: '/panel/factory-details',
                    icon: 'project',
                    module: 'FactoryDetails',
                }),
            },
            {
                key: 'projects-reports',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Relatórios - Projetos' },
            },
            {
                key: 'projects-quality-record',
                icon: 'audit',
                permission: 'ProjectsQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'projects-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/projects-quality-record',
                    icon: 'audit',
                    module: 'ProjectsQualityRecord',
                }),
            },
            {
                key: 'projects-quality-procedures',
                icon: 'audit',
                permission: 'ProjectsQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'projects-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/projects-quality-procedures',
                    icon: 'audit',
                    module: 'ProjectsQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'Suprimentos',
        icon: 'shopping-cart',
        key: 'supplies',
        submenus: [
            {
                key: 'grupo-provedores',
                icon: 'cluster',
                permission: 'InputProviderGroupRead',
                module: Modules.InputProviderGroupModule,
            },
            {
                key: 'provedores',
                icon: 'idcard',
                permission: 'InputProviderRead',
                module: Modules.InputProviderModule,
            },
            {
                key: 'insumo',
                icon: 'dropbox',
                permission: 'InputRead',
                module: Modules.InputModule,
            },
            {
                key: 'relatorio-insumo',
                icon: 'profile',
                permission: 'InputReportsRead',
                module: Modules.InputReportsModule,
            },
            {
                key: 'supplies-programation',
                icon: 'dropbox',
                permission: 'InputClientReportRead',
                module: Modules.InputClientReportModule,
            },
            {
                key: 'cotacao-precos',
                icon: 'calculator',
                permission: 'InputOrderPriceQuoteRead',
                module: Modules.InputOrderPriceQuoteModule,
            },
            {
                key: 'pedido-compra',
                icon: 'file-ppt',
                permission: 'InputOrderRead',
                module: Modules.InputOrderModule,
            },
            {
                key: 'relatorio-pedidos-compra',
                icon: 'profile',
                permission: 'InputOrderReportRead',
                module: Modules.InputOrderReportModule,
            },
            {
                key: 'inventory-control',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Controle de Estoque' },
            },
            {
                key: 'avaliacao-qualidade',
                icon: 'form',
                permission: 'InputQualityAssessmentRead',
                module: Modules.InputQualityAssessmentModule,
            },
            {
                key: 'supplies-registration-form',
                icon: 'book',
                permission: 'SuppliesRegistrationFormRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'supplies-registration-form',
                    titleModule: 'Ficha Cadastro',
                    routerBase: '/panel/supplies-registration-form',
                    icon: 'book',
                    module: 'SuppliesRegistrationForm',
                }),
            },
            {
                key: 'supplies-quality-record',
                icon: 'audit',
                permission: 'SuppliesQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'supplies-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/supplies-quality-record',
                    icon: 'audit',
                    module: 'SuppliesQualityRecord',
                }),
            },
            {
                key: 'supplies-quality-procedures',
                icon: 'audit',
                permission: 'SuppliesQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'supplies-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/supplies-quality-procedures',
                    icon: 'audit',
                    module: 'SuppliesQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'Indústria',
        icon: 'tool',
        key: 'industria',
        submenus: [
            {
                key: 'production-schedule',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Cronograma de Produção' },
            },
            {
                key: 'production-planning-control',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Planejamento e Controle de Produção' },
            },
            {
                key: 'factory-order',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Ordem de Fabricação' },
            },
            {
                key: 'expedition',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Expedição' },
            },
            {
                key: 'production-reports',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Relatórios - Produção' },
            },
            {
                key: 'industria-quality-record',
                icon: 'audit',
                permission: 'IndustriaQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'industria-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/industria-quality-record',
                    icon: 'audit',
                    module: 'IndustriaQualityRecord',
                }),
            },
            {
                key: 'industria-quality-procedures',
                icon: 'audit',
                permission: 'IndustriaQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'industria-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/industria-quality-procedures',
                    icon: 'audit',
                    module: 'IndustriaQualityProcedures',
                }),
            },
            {
                key: 'industria-it',
                icon: 'audit',
                permission: 'IndustriaITRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'industria-it',
                    titleModule: 'IT - Instruções de Trabalho',
                    routerBase: '/panel/industria-it',
                    icon: 'audit',
                    module: 'IndustriaIT',
                }),
            },
        ]
    },
    {
        title: 'Manutenção',
        icon: 'tool',
        key: 'maintenance',
        submenus: [
            {
                key: 'maintenance-measuring',
                icon: 'dashboard',
                permission: 'IndustrialMaintenenceDocsRead',
                module: Modules.MeasuringEquipamentModule,
            },
            {
                key: 'maintenance-equips',
                icon: 'tool',
                permission: 'IndustrialMaintenenceDocsRead',
                module: Modules.MachinesAndEquipamentModule,
            },
            {
                key: 'maintenance-industry',
                icon: 'api',
                permission: 'IndustrialMaintenenceDocsRead',
                module: Modules.IndustrialFacilitiesModule,
            },
            {
                key: 'maintenance-reports',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Relatórios - Manutenção' },
            },
            {
                key: 'maintenance-quality-record',
                icon: 'audit',
                permission: 'MaintenanceQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'maintenance-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/maintenance-quality-record',
                    icon: 'audit',
                    module: 'MaintenanceQualityRecord',
                }),
            },
            {
                key: 'maintenance-quality-procedures',
                icon: 'audit',
                permission: 'MaintenanceQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'maintenance-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/maintenance-quality-procedures',
                    icon: 'audit',
                    module: 'MaintenanceQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'Service',
        icon: 'team',
        key: 'service',
        submenus: [
            {
                key: 'instalation-schedule',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Cronograma de Instalação' },
            },
            {
                key: 'service-order',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'OS - Ordem de Serviços de Instalação' },
            },
            {
                key: 'work-watch',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Vistoria de obras' },
            },
            {
                key: 'sac',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'SAC - Assistência Técnica' },
            },
            {
                key: 'customer_support',
                icon: 'folder-open',
                permission: 'OrderDocsRead',
                module: techDocs('customer_support', 'Suporte ao cliente', '/panel/documentos-tecnicos-suporte-cliente')
            },
            {
                key: 'service-proposal',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Proposta de Serviço' },
            },
            {
                key: 'service-pesquisa-satisfacao',
                icon: 'message',
                permission: 'PcpoSatisfactionSurveyRead',
                module: Modules.PcpoSatisfactionSurveyModule,
            },
            {
                key: 'service-reports',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Relatórios - Serviços' },
            },
            {
                key: 'installer-manual',
                icon: 'read',
                permission: 'InstallerManualRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'installer-manual',
                    titleModule: 'Manual do instalador',
                    routerBase: '/panel/installer-manual',
                    icon: 'read',
                    module: 'InstallerManual',
                }),
            },
            {
                key: 'service-quality-record',
                icon: 'audit',
                permission: 'ServiceQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'service-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/service-quality-record',
                    icon: 'audit',
                    module: 'ServiceQualityRecord',
                }),
            },
            {
                key: 'service-quality-procedures',
                icon: 'audit',
                permission: 'ServiceQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'service-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/service-quality-procedures',
                    icon: 'audit',
                    module: 'ServiceQualityProcedures',
                }),
            },
            {
                key: 'service-it',
                icon: 'audit',
                permission: 'ServiceITRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'service-it',
                    titleModule: 'IT - Instruções de Trabalho',
                    routerBase: '/panel/service-it',
                    icon: 'audit',
                    module: 'ServiceIT',
                }),
            },
        ]
    },
    {
        title: 'RH-DHO',
        icon: 'apartment',
        key: 'rh',
        submenus: [
            {
                key: 'collaborator',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Cadastro de colaboradores' },
            },
            {
                key: 'office-manual',
                icon: 'read',
                permission: 'OfficeManualRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'office-manual',
                    titleModule: 'Manual descritivo de cargos',
                    routerBase: '/panel/office-manual',
                    icon: 'read',
                    module: 'OfficeManual',
                }),
            },
            {
                key: 'code-of-ethics',
                icon: 'read',
                permission: 'CodeOfEthicsRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'code-of-ethics',
                    titleModule: 'Código de ética',
                    routerBase: '/panel/code-of-ethics',
                    icon: 'read',
                    module: 'CodeOfEthics',
                }),
            },
            {
                key: 'jobs-and-wages',
                icon: 'apartment',
                permission: 'JobsAndWagesRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'jobs-and-wages',
                    titleModule: 'Plano de cargos e salários',
                    routerBase: '/panel/jobs-and-wages',
                    icon: 'apartment',
                    module: 'JobsAndWages',
                }),
            },
            {
                key: 'curriculum',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Currículos aprovados' },
            },
            {
                key: 'rh-quality-record',
                icon: 'audit',
                permission: 'DHOQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'rh-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/rh-quality-record',
                    icon: 'audit',
                    module: 'DHOQualityRecord',
                }),
            },
            {
                key: 'rh-quality-procedures',
                icon: 'audit',
                permission: 'DHOQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'rh-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/rh-quality-procedures',
                    icon: 'audit',
                    module: 'DHOQualityProcedures',
                }),
            },
        ]
    },
    {
        title: 'SGQ',
        icon: 'audit',
        key: 'sgq',
        submenus: [
            {
                key: 'strategic-planning',
                icon: 'schedule',
                permission: 'StrategicPlanningRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'strategic-planning',
                    titleModule: 'Planejamento estratégico',
                    routerBase: '/panel/strategic-planning',
                    icon: 'schedule',
                    module: 'StrategicPlanning',
                }),
            },
            {
                key: 'quality-indicators',
                icon: 'line-chart',
                permission: 'QualityIndicatorsRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'quality-indicators',
                    titleModule: 'Indicadores da qualidade',
                    routerBase: '/panel/quality-indicators',
                    icon: 'line-chart',
                    module: 'QualityIndicators',
                }),
            },
            {
                key: 'product-certifications',
                icon: 'audit',
                permission: 'ProductCertificationsRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'product-certifications',
                    titleModule: 'Certificação de produtos',
                    routerBase: '/panel/product-certifications',
                    icon: 'audit',
                    module: 'ProductCertifications',
                }),
            },
            {
                key: 'external-audit',
                icon: 'audit',
                permission: 'ExternalAuditRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'external-audit',
                    titleModule: 'Auditoria externa',
                    routerBase: '/panel/external-audit',
                    icon: 'audit',
                    module: 'ExternalAudit',
                }),
            },
            {
                key: 'quality-manual',
                icon: 'read',
                permission: 'QualityManualRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'quality-manual',
                    titleModule: 'Manual da qualidade',
                    routerBase: '/panel/quality-manual',
                    icon: 'read',
                    module: 'QualityManual',
                }),
            },
            {
                key: 'sgq-quality-record',
                icon: 'audit',
                permission: 'SGQQualityRecordRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'sgq-quality-record',
                    titleModule: 'RM - Registros da Qualidade',
                    routerBase: '/panel/sgq-quality-record',
                    icon: 'audit',
                    module: 'SGQQualityRecord',
                }),
            },
            {
                key: 'sgq-quality-procedures',
                icon: 'audit',
                permission: 'SGQQualityProceduresRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'sgq-quality-procedures',
                    titleModule: 'PQ - Procedimentos da Qualidade',
                    routerBase: '/panel/sgq-quality-procedures',
                    icon: 'audit',
                    module: 'SGQQualityProcedures',
                }),
            },
            {
                key: 'sgq-it',
                icon: 'audit',
                permission: 'SGQITRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'sgq-it',
                    titleModule: 'IT - Instruções de Trabalho',
                    routerBase: '/panel/sgq-it',
                    icon: 'audit',
                    module: 'SGQIT',
                }),
            },
        ]
    },
    {
        title: 'Marketing',
        icon: 'bulb',
        key: 'marketing',
        submenus: [
            {
                key: 'presentations',
                icon: 'setting',
                permission: null,
                module: { "title-module": 'Apresentações' },
            },
            {
                key: 'traffic-management',
                icon: 'area-chart',
                permission: 'TrafficManagementRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'traffic-management',
                    titleModule: 'Gestão tráfego',
                    routerBase: '/panel/traffic-management',
                    icon: 'area-chart',
                    module: 'TrafficManagement',
                }),
            },
            {
                key: 'market-research',
                icon: 'snippets',
                permission: 'MarketResearchRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'market-research',
                    titleModule: 'Pesquisa Mercado',
                    routerBase: '/panel/market-research',
                    icon: 'snippets',
                    module: 'MarketResearch',
                }),
            },
            {
                key: 'design-kit',
                icon: 'edit',
                permission: 'DesignKitRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'design-kit',
                    titleModule: 'Design Kit',
                    routerBase: '/panel/design-kit',
                    icon: 'edit',
                    module: 'DesignKit',
                }),
            },
            {
                key: 'marketing-planning',
                icon: 'schedule',
                permission: 'MarketingPlanningRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'marketing-planning',
                    titleModule: 'Planejamento',
                    routerBase: '/panel/marketing-planning',
                    icon: 'schedule',
                    module: 'MarketingPlanning',
                }),
            },
            {
                key: 'marketing-ideas',
                icon: 'bulb',
                permission: 'MarketingIdeasRead',
                module: Modules.AbntIptDocsModule({
                    moduleToFilter: 'marketing-ideas',
                    titleModule: 'Ideias',
                    routerBase: '/panel/marketing-ideas',
                    icon: 'bulb',
                    module: 'MarketingIdeas',
                }),
            },
        ]
    },
    {
        title: 'E-learning',
        icon: 'folder-open',
        key: 'docs-tech',
        submenus: defaultOrderDocsTypes.map(el => {
            return {
                key: el.filterType,
                icon: 'folder-open',
                permission: 'OrderDocsRead',
                module: techDocs(el.filterType, el.label, el.path)
            }
        })
    },
    {
        title: 'Componentes e Kits',
        icon: 'box-plot',
        key: 'componentes-kits',
        submenus: [
            {
                key: 'componente-kit',
                icon: 'box-plot',
                permission: 'ComponenteKitRead',
                module: Modules.ComponentKitModule,
            },
            {
                key: 'montagem-kit',
                icon: 'box-plot',
                permission: 'MountingKitRead',
                module: Modules.MountingKitModule,
            },
            {
                key: 'instalacao-kit',
                icon: 'box-plot',
                permission: 'InstalationKitRead',
                module: Modules.InstalationKitModule,
            },
            {
                key: 'kit',
                icon: 'box-plot',
                permission: 'KitRead',
                module: Modules.KitModule,
            },
            {
                key: 'matriz-dimensoes',
                icon: 'table',
                permission: 'ConvertDimensionRead',
                module: Modules.MatrizDimensionModule,
            },
            {
                key: 'matriz-fechaduras',
                icon: 'key',
                permission: null,
                module: Modules.MatrizFechModule,
            },
            {
                key: 'matriz-ferragens',
                icon: 'key',
                permission: null,
                module: Modules.MatrizDoorLocksModule,
            },
            {
                key: 'matriz-arremates',
                icon: 'table',
                permission: 'MatrizAlizRead',
                module: Modules.MatrizAlizModule,
            },
            {
                key: 'matriz-paineis',
                icon: 'windows',
                permission: 'MatrizPanelRead',
                module: Modules.MatrizPanelModule,
            },
            {
                key: 'matriz-espessura-marco',
                icon: 'shrink',
                permission: 'MatrizMarcoThickness',
                module: Modules.MatrizMarcoThickness,
            },
            {
                key: 'checklist-configs',
                icon: 'table',
                permission: 'ChecklistConfigsRead',
                module: Modules.ChecklistConfigsModule,
            },
            {
                title: 'Acabamentos',
                key: 'grupos-sub-finishing',
                icon: 'bg-colors',
                submenus: [
                    {
                        key: 'colecoes',
                        icon: 'bg-colors',
                        permission: 'CollectionsRead',
                        module: Modules.CollectionModule,
                    },
                    {
                        key: 'padrao_cores',
                        icon: 'bg-colors',
                        permission: 'PatternColorsRead',
                        module: Modules.PatternColorsModule,
                    },
                    {
                        key: 'acabamentos',
                        icon: 'bg-colors',
                        permission: 'FinishingRead',
                        module: Modules.FinishingModule,
                    },
                    {
                        key: 'matriz_acabamentos',
                        icon: 'table',
                        permission: 'MatrizFinishingRead',
                        module: Modules.MatrizFinishingModule,
                    },
                ]
            },
            {
                key: 'tabela-comparativa',
                icon: 'table',
                permission: 'ComparativeTableRead',
                module: Modules.comparativeTableModule,
            },
            {
                key: 'region',
                icon: 'environment',
                permission: 'RegionRead',
                module: Modules.RegionModule,
            },
            {
                key: 'sales-channel',
                icon: 'branches',
                permission: 'SalesChannelRead',
                module: Modules.SalesChannelModule,
            },
            {
                title: 'Custo Fixo',
                key: 'fixed-cost-submenu',
                icon: 'dollar',
                submenus: [
                    {
                        key: 'staffing',
                        icon: 'team',
                        permission: 'StaffingRead',
                        module: Modules.StaffingModule,
                    },
                    {
                        key: 'fixed-cost',
                        icon: 'dollar',
                        permission: 'FixedCostRead',
                        module: Modules.FixedCostModule,
                    },
                    {
                        key: 'man_hour',
                        icon: 'man',
                        permission: 'ManHourRead',
                        module: Modules.ManHourModule,
                    },
                ]
            },
        ]
    },
    {
        title: 'Configurações',
        icon: 'setting',
        key: 'grupos-configs',
        submenus: [
            {
                key: 'config',
                icon: 'key',
                permission: 'ConfigRead',
                module: Modules.ConfigsModule,
            },
            {
                title: 'Instalação',
                key: 'grupos-sub-instalation',
                icon: 'tool',
                submenus: [
                    {
                        key: 'config-instalation',
                        icon: 'tool',
                        permission: 'InstalationRead',
                        module: Modules.InstalationModule,
                    },
                    {
                        key: 'config-typework',
                        icon: 'tool',
                        permission: 'TypeWorkRead',
                        module: Modules.TypeWorkModule,
                    },
                    {
                        key: 'config-mobilitywork',
                        icon: 'hdd',
                        permission: 'MobilityWorkRead',
                        module: Modules.MobilityWorkModule,
                    },
                    {
                        key: 'config-techmanager',
                        icon: 'user',
                        permission: 'TechManagerRead',
                        module: Modules.TechManagerModule,
                    },
                    {
                        key: 'config-techassistance',
                        icon: 'tool',
                        permission: 'TechAssistanceRead',
                        module: Modules.TechAssistanceModule,
                    },
                    {
                        key: 'config-teammob',
                        icon: 'car',
                        permission: 'TeamMobRead',
                        module: Modules.TeamMobModule,
                    },
                ]
            },
            {
                title: 'Comercial',
                key: 'grupos-sub-sales',
                icon: 'shop',
                submenus: [
                    {
                        key: 'estagio-obra',
                        icon: 'home',
                        permission: 'WorkStageRead',
                        module: Modules.WorkStageModule,
                    },
                    {
                        key: 'previsao-compra',
                        icon: 'calendar',
                        permission: 'PrevisionBuyRead',
                        module: Modules.PrevisionBuyModule,
                    },
                    {
                        key: 'empresa-faturamento',
                        icon: 'idcard',
                        permission: 'BillingCompanyRead',
                        module: Modules.BillingCompanyModule,
                    },
                    {
                        key: 'provider',
                        icon: 'shopping-cart',
                        permission: 'ProviderRead',
                        module: Modules.ProviderModule,
                    },
                ]
            },
            {
                title: 'Desempenho',
                key: 'grupos-sub-performace',
                icon: 'apartment',
                submenus: [
                    {
                        key: 'config-instalation-kit',
                        icon: 'shop',
                        permission: 'OccupationRead',
                        module: Modules.OccupationModule,
                    },
                    {
                        key: 'performance',
                        icon: 'apartment',
                        permission: 'ProfilePerformanceRead',
                        module: Modules.PerformanceModule,
                    },
                ]
            },
            {
                title: 'Configuradores',
                key: 'grupos-sub-configs',
                icon: 'setting',
                submenus: [
                    {
                        key: 'discounts_configs',
                        icon: 'dollar',
                        permission: 'DiscountsConfigsRead',
                        module: Modules.DiscountsConfigsModule,
                    },
                    {
                        key: 'door_display_configs',
                        icon: 'border-outer',
                        permission: 'DoorDisplayConfigsRead',
                        module: Modules.DoorDisplayConfigsModule,
                    },
                    {
                        key: 'component_kit_image_configs',
                        icon: 'file-image',
                        permission: 'ComponentKitImageConfigsRead',
                        module: Modules.ComponentKitImageConfigsModule,
                    },
                    {
                        key: 'teoric_mass_configs',
                        icon: 'gold',
                        permission: 'TeoricMassConfigsRead',
                        module: Modules.TeoricMassConfigsModule,
                    },
                    {
                        key: 'convertion_configs',
                        icon: 'swap',
                        permission: 'ConvertionConfigsRead',
                        module: Modules.ConvertionConfigsModule,
                    },
                    {
                        key: 'fixed_observation_configs',
                        icon: 'form',
                        permission: 'FixedObservationConfigsRead',
                        module: Modules.FixedObservationConfigsModule,
                    },
                    {
                        key: 'architectural_services',
                        icon: 'form',
                        permission: 'ArchitecturalServicesRead',
                        module: Modules.ArchitecturalServicesModule,
                    },
                ]
            },
        ]
    },
    {
        title: 'Usuários',
        icon: 'user',
        key: 'grupos-usuarios',
        submenus: [
            {
                key: 'usuario',
                icon: 'user',
                permission: 'UserRead',
                module: Modules.UserModule,
                isUser: true,
            },
            {
                key: 'grupo',
                icon: 'team',
                permission: 'GroupRead',
                module: Modules.UserGroupModule,
            },
        ]
    },
]

export {
    sideMenu,
}