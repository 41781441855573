import React from "react";
import Parse from "parse";
import { Switch, Checkbox, Icon, Form as AntForm, Collapse } from "antd";

export default {
  fields: [
    [
      {
        title: "Nome",
        key: "name",
        type: "text",
        required: true,
      },
      {
        type: "switch",
        title: "Ativo",
        key: "active",
        defaultChecked: true,
        required: true,
      },
    ],
    [
      {
        title: "Grupos do usuário",
        key: "permission",
        type: "render",
        options: [
          {
            title: "Comercial",
            modules: [
              {
                title: "Pesquisa de qualificação",
                module: "QualificationSurvey",
              },
              {
                title: "Solicitação de Proposta",
                module: "ProposalRequest",
                options: [
                  {
                    label: "Filtrar usuário",
                    value: "ProposalRequestFilterUser",
                  },
                  {
                    label: "Realizar análise prévia",
                    value: "ProposalRequestPriorAnalysis",
                  },
                  { label: "Atualizar status", value: "ProposalRequestStatus" },
                ],
              },
              {
                title: "Propostas",
                module: "Proposal",
                options: [
                  { label: "Gerar Proposta", value: "GenerateProposal" },
                  { label: "PDF Proposta", value: "viewProposalPDF-proposal" },
                  {
                    label: "PDF Analítico",
                    value: "viewAnaliticProposalPDF-proposal",
                  },
                  {
                    label: "Desconto por item",
                    value: "ProposalDiscountPerItem",
                  },
                  {
                    label: "Desconto comercial",
                    value: "ProposalCommercialDiscount",
                  },
                  {
                    label: "Desconto financeiro",
                    value: "ProposalFinancialDiscount",
                  },
                  {
                    label: "Alterar canal de vendas",
                    value: "ProposalSalesChannel",
                  },
                  {
                    label: "Regra 25% do frete",
                    value: "ProposalDeliveryValueRule",
                  },
                  {
                    label: "Retirar dobradiças",
                    value: "ProposalRemoveDobradica",
                  },
                ],
              },
              {
                title: "Gerenciar proposta",
                module: "ProposalManagement",
                options: [
                  {
                    label: "Gerar Quadro de Soluções",
                    value: "GenerateSolutions",
                  },
                  { label: "PDF Proposta", value: "viewProposalPDF" },
                  { label: "PDF Analítico", value: "viewAnaliticProposalPDF" },
                  { label: "PDF Insumo", value: "viewInputReportPDF" },
                  { label: "PDF CRM", value: "viewCrmPDF" },
                  { label: "CNPJ Receita", value: "viewReceitaPDF" },
                  { label: "Histórico", value: "viewHistoric" },
                  {
                    label: "Enviar Proposta via E-mail",
                    value: "sendProposalByEmail",
                  },
                ],
              },
              { title: "Tabela de preços", module: "PriceList" },
              { title: "Gerar pedido", module: "Order" },
              { title: "Clientes", module: "Client" },
              { title: "Representantes", module: "TradeRepresentative" },
              {
                title: "Pesquisa de atendimento",
                module: "CustomerSatisfactionSurvey",
              },
              {
                title: "Relatórios - Comercial",
                module: "ComercialManagement",
              },
              {
                title: "RM - Registros da Qualidade",
                module: "CommercialQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "CommercialQualityProcedures",
              },
            ],
          },
          {
            title: "Gestão do Pedido",
            modules: [
              { title: "Mapa de pedidos", module: "OrderMap" },
              { title: "Pasta do pedido", module: "OrderFolder" },
              {
                title: "RM - Registros da Qualidade",
                module: "AdmVendasQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "AdmVendasQualityProcedures",
              },
            ],
          },
          {
            title: "ADM vendas",
            modules: [
              {
                title: "RM - Registros da Qualidade",
                module: "AdmVendasQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "AdmVendasQualityProcedures",
              },
            ],
          },
          {
            title: "Projetos P&D",
            modules: [
              {
                title: "Padronização de Produtos",
                module: "ProductStandardization",
              },
              { title: "BIM", module: "Bim" },
              { title: "Detalhes de Fabricação", module: "FactoryDetails" },
              {
                title: "RM - Registros da Qualidade",
                module: "ProjectsQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "ProjectsQualityProcedures",
              },
            ],
          },
          {
            title: "Suprimentos",
            modules: [
              { title: "Grupo de Provedores", module: "InputProviderGroup" },
              { title: "Provedores", module: "InputProvider" },
              { title: "Insumos", module: "Input" },
              { title: "Relatório de Insumos", module: "InputReports" },
              { title: "Programação Insumos", module: "InputClientReport" },
              { title: "Cotação de Preços", module: "InputOrderPriceQuote" },
              { title: "Pedido de Compra", module: "InputOrder" },
              { title: "Relatório de Pedidos", module: "InputOrderReport" },
              {
                title: "Avaliação Qualidade",
                module: "InputQualityAssessment",
              },
              { title: "Ficha Cadastro", module: "SuppliesRegistrationForm" },
              {
                title: "RM - Registros da Qualidade",
                module: "SuppliesQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "SuppliesQualityProcedures",
              },
            ],
          },
          {
            title: "Indústria",
            modules: [
              {
                title: "RM - Registros da Qualidade",
                module: "IndustriaQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "IndustriaQualityProcedures",
              },
              { title: "IT - Instruções de Trabalho", module: "IndustriaIT" },
            ],
          },
          {
            title: "Manutenção",
            modules: [
              {
                title: "Equipamentos de medição",
                module: "IndustrialMaintenenceDocs",
              },
              {
                title: "Máquinas e equipamentos",
                module: "IndustrialMaintenenceDocs",
              },
              {
                title: "Instalações industriais",
                module: "IndustrialMaintenenceDocs",
              },
              {
                title: "RM - Registros da Qualidade",
                module: "MaintenanceQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "MaintenanceQualityProcedures",
              },
            ],
          },
          {
            title: "Service",
            modules: [
              {
                title: "Pesquisa de satisfação",
                module: "PcpoSatisfactionSurvey",
              },
              { title: "Manual do instalador", module: "InstallerManual" },
              {
                title: "RM - Registros da Qualidade",
                module: "ServiceQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "ServiceQualityProcedures",
              },
              { title: "IT - Instruções de Trabalho", module: "ServiceIT" },
            ],
          },
          {
            title: "RH-DHO",
            modules: [
              { title: "Manual descritivo de cargos", module: "OfficeManual" },
              { title: "Código de ética", module: "CodeOfEthics" },
              { title: "Plano de cargos e salários", module: "JobsAndWages" },
              {
                title: "RM - Registros da Qualidade",
                module: "DHOQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "DHOQualityProcedures",
              },
            ],
          },
          {
            title: "SGQ",
            modules: [
              {
                title: "Planejamento estratégico",
                module: "StrategicPlanning",
              },
              {
                title: "Indicadores da qualidade",
                module: "QualityIndicators",
              },
              {
                title: "Certificação de produtos",
                module: "ProductCertifications",
              },
              { title: "Auditoria externa", module: "ExternalAudit" },
              { title: "Manual da qualidade", module: "QualityManual" },
              {
                title: "RM - Registros da Qualidade",
                module: "SGQQualityRecord",
              },
              {
                title: "PQ - Procedimentos da Qualidade",
                module: "SGQQualityProcedures",
              },
              { title: "IT - Instruções de Trabalho", module: "SGQIT" },
            ],
          },
          {
            title: "Marketing",
            modules: [
              { title: "Gestão tráfego", module: "TrafficManagement" },
              { title: "Pesquisa Mercado", module: "MarketResearch" },
              { title: "Design Kit", module: "DesignKit" },
              { title: "Planejamento", module: "MarketingPlanning" },
              { title: "Ideias", module: "MarketingIdeas" },
            ],
          },
          {
            title: "E-learning",
            modules: [
              { title: "Documentos - E-learning", module: "OrderDocs" },
            ],
          },
          {
            title: "Componentes e Kits",
            modules: [
              { title: "Componente de Kit", module: "ComponenteKit" },
              { title: "Montagem de Kit", module: "MountingKit" },
              { title: "Instalação de Kit", module: "InstalationKit" },
              { title: "Ficha Técnica de Kits", module: "Kit" },
              { title: "Matriz de Dimensões", module: "MatrizDimension" },
              { title: "Matriz de Arremates", module: "MatrizAliz" },
              { title: "Matriz de Painéis", module: "MatrizPanel" },
              {
                title: "Matriz Espessura do Marco",
                module: "MatrizMarcoThickness",
              },
              { title: "Checklist Especificação", module: "ChecklistConfigs" },
              { title: "Acabamentos - Coleções", module: "Collections" },
              {
                title: "Acabamentos - Padrões de Cores",
                module: "PatternColors",
              },
              { title: "Acabamentos - Acabamentos", module: "Finishing" },
              { title: "Tabela comparativa", module: "ComparativeTable" },
              { title: "Transporte e Logística", module: "Region" },
              { title: "Canal de Vendas", module: "SalesChannel" },
              { title: "Custo Fixo - Quadro de pessoal", module: "Staffing" },
              { title: "Custo Fixo - Custo fixo", module: "FixedCost" },
              { title: "Custo Fixo - Homem Hora", module: "ManHour" },
            ],
          },
          {
            title: "Configurações",
            modules: [
              { title: "Chave-valor", module: "Config" },

              { title: "Instalação - Instalação", module: "Instalation" },
              { title: "Instalação - Tipo de Instalação", module: "TypeWork" },
              {
                title: "Instalação - Mobilidade da Obra",
                module: "MobilityWork",
              },
              {
                title: "Instalação - Responsável Técnico",
                module: "TechManager",
              },
              {
                title: "Instalação - Assistência Técnica",
                module: "TechAssistance",
              },
              {
                title: "Instalação - Mobilização da Equipe",
                module: "TeamMob",
              },

              { title: "Comercial - Estágio da Obra", module: "WorkStage" },
              {
                title: "Comercial - Previsão de Compra",
                module: "PrevisionBuy",
              },
              {
                title: "Comercial - Empresa para faturamento",
                module: "BillingCompany",
              },
              { title: "Comercial - Fornecedor/Marca", module: "Provider" },

              { title: "Desempenho - Ocupação", module: "Occupation" },
              {
                title: "Desempenho - Perfil de desempenho",
                module: "ProfilePerformance",
              },

              {
                title: "Configuradores - Descontos",
                module: "DiscountsConfigs",
              },
              { title: "Configuradores - Visor", module: "DoorDisplayConfigs" },
              {
                title: "Configuradores - Imagem do componente",
                module: "ComponentKitImageConfigs",
              },
              {
                title: "Configuradores - Massa teórica",
                module: "TeoricMassConfigs",
              },
              {
                title: "Configuradores - Conversão",
                module: "ConvertionConfigs",
              },
              {
                title: "Configuradores - Observação fixa",
                module: "FixedObservationConfigs",
              },
              {
                title: "Configuradores - Serviços de arquitetura",
                module: "ArchitecturalServices",
              },
            ],
          },
          {
            title: "Usuários",
            modules: [
              { title: "Usuários", module: "User" },
              { title: "Grupos de Usuário", module: "Group" },
            ],
          },
          {
            title: "Extras",
            modules: [{ title: "Documentos ABNT IPT", module: "AbntIptDocs" }],
          },
        ],
        render: (_, Form, node) => {
          return (
            <Collapse defaultActiveKey={["1"]}>
              {node.options.map((menu, menuIndex) => {
                return (
                  <Collapse.Panel header={menu.title} key={`menu_${menuIndex}`}>
                    {menu.modules.map((option, index) => {
                      let values = [];

                      if (
                        (Form.state.formRef.permission || {}).hasOwnProperty(
                          option.module
                        )
                      )
                        values =
                          Form.state.formRef.permission[option.module].value;

                      const label = (option) => {
                        let valuesOption = allOptions(option).map(
                          (o) => o.value
                        );
                        let checked =
                          values.filter((value) => valuesOption.includes(value))
                            .length === valuesOption.length;
                        return (
                          <div>
                            <Checkbox
                              checked={checked}
                              onChange={(event) =>
                                node.handleChange(
                                  event.target.checked
                                    ? allOptions(option).map((op) => op.value)
                                    : [],
                                  Form,
                                  option.module,
                                  node
                                )
                              }
                            />
                            <span style={{ fontWeight: "bold" }}>
                              {option.title}
                            </span>
                          </div>
                        );
                      };

                      const allOptions = (option) => {
                        return [
                          {
                            label: "Visualizar",
                            value: `${option.module}Read`,
                          },
                          { label: "Criar", value: `${option.module}Create` },
                          { label: "Editar", value: `${option.module}Update` },
                          { label: "Apagar", value: `${option.module}Delete` },
                        ]
                          .concat(option.options || [])
                          .concat(
                            option.module !== "Proposal" &&
                              option.module !== "ProposalManagement" &&
                              option.module !== "User"
                              ? [
                                {
                                  label: "Clonar",
                                  value: `${option.module}Copy`,
                                },
                              ]
                              : []
                          );
                      };

                      return (
                        <AntForm.Item
                          label={label(option)}
                          key={index}
                          style={{ margin: "15px 0" }}
                        >
                          <Checkbox.Group
                            value={values}
                            onChange={(event) =>
                              node.handleChange(
                                event,
                                Form,
                                option.module,
                                node
                              )
                            }
                            options={allOptions(option)}
                          />
                        </AntForm.Item>
                      );
                    })}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          );
        },
        handleChange: (values, FormModule, moduleName, node) => {
          let query = new Parse.Query("Permission").limit(100000);
          query.containedIn("action", Object.values(values));

          query.find().then((permissionList) => {
            let perms = {
              ...FormModule.state.formRef.permission,
              [moduleName]: { value: values, parseObjectList: permissionList },
            };
            FormModule.updateRefValue(node, perms);
          });
        },
        beforeSave: (FormModule, parseObject, node) => {
          let $RelationObject = new Parse.Relation(parseObject, node.key);
          let relations = Object.values(
            FormModule.state.formRef.permission || {}
          ).reduce((acc, value) => {
            return [...acc, ...value.parseObjectList];
          }, []);

          if (node.permissionList) {
            $RelationObject.remove(node.permissionList);
          }

          if (!relations.length) {
            parseObject.set("permission", null);
            let $NewRelationObject = new Parse.Relation(parseObject, node.key);
            return $NewRelationObject;
          }

          return $RelationObject.add(relations);
        },
      },
    ],
  ],
  submit: {
    collection: "Group",
  },
  className: "",
  schema: [
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
      type: "text",
    },
    {
      title: "Ativo",
      key: "active",
      dataIndex: "active",
      render: (value) => {
        return <Switch checked={value} />;
      },
    },
  ],
  "title-module": "Grupos de Usuários",
  "title-navigation": (
    <div>
      <Icon type="team" /> Grupos de Usuário
    </div>
  ),
  module: "UserGroup",
  "router-base": "/panel/grupos",
  ParseEditableObject: (FormModule, response) => {
    let node = FormModule.getNodesByKey("permission");
    response
      .get("permission")
      .query()
      .limit(100000)
      .find()
      .then((response) => {
        node.permissionList = response || [];
        let params = response.reduce((obj, parseValue) => {
          if (!obj.hasOwnProperty(parseValue.get("module")))
            obj[parseValue.get("module")] = { value: [], parseObjectList: [] };

          obj[parseValue.get("module")].parseObjectList.push(parseValue);
          obj[parseValue.get("module")].value.push(parseValue.get("action"));

          return obj;
        }, {});

        FormModule.updateRefValue(node, params);
      });

    let formRef = response.toJSON();
    formRef.permission = {};

    return formRef;
  },
};
